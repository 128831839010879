/deep/ .logo {
  vertical-align: baseline;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 0;
  align-self: end;

  &.circle {
    width: 110px;
    height: 110px;

    &.bank {
      background-image: url('~@/assets/images/channel/bank.png');
    }

    &.international {
      background-image: url('~@/assets/images/channel/international.png');
    }

    &.bitwallet {
      background-image: url('~@/assets/images/channel/bitwallet.png');
    }

    &.bitcoin {
      background-image: url('~@/assets/images/channel/bitcoin.png');
    }

    &.USDT_1 {
      background-image: url('~@/assets/images/channel/USDT_1.png');
    }

    &.USDT_2 {
      background-image: url('~@/assets/images/channel/USDT_2.png');
    }

    &.bankTha {
      background-image: url('~@/assets/images/channel/bank_thailand_primary.png');
    }

    &.bankMysPrimary {
      background-image: url('~@/assets/images/channel/bank_malaysia_primary.png');
    }

    &.bankMysSecondary {
      background-image: url('~@/assets/images/channel/bank_malaysia_secondary.png');
    }

    &.bankVumPrimary {
      background-image: url('~@/assets/images/channel/bank_vietnam_primary.png');
    }

    &.bankVumSecondary {
      background-image: url('~@/assets/images/channel/bank_vietnam_secondary.png');
    }

    &.bankNga {
      background-image: url('~@/assets/images/channel/bank_nigeria.png');
    }

    &.bankIdnPrimary {
      background-image: url('~@/assets/images/channel/bank_indonesia_primary.png');
    }

    &.bankIdnSecondary {
      background-image: url('~@/assets/images/channel/bank_indonesia_Secondary.png');
    }

    &.bankIdnTertiary {
      background-image: url('~@/assets/images/channel/bank_indonesia_Secondary.png');
    }

    &.bankIda {
      background-image: url('~@/assets/images/channel/bank_india.png');
    }

    &.bankZar {
      background-image: url('~@/assets/images/channel/bank_southafrica.png');
    }

    &.bankUganda {
      background-image: url('~@/assets/images/channel/bank_southafrica.png');
    }

    &.bankRwanda {
      background-image: url('~@/assets/images/channel/bank_southafrica.png');
    }

    &.bankZambia {
      background-image: url('~@/assets/images/channel/bank_zambia.png');
    }

    &.bankCongo {
      background-image: url('~@/assets/images/channel/bank_congo.png');
    }

    &.bankCameroon {
      background-image: url('~@/assets/images/channel/bank_cameroon.png');
    }

    &.bankBurundi {
      background-image: url('~@/assets/images/channel/bank_burundi.png');
    }

    &.bankKenya {
      background-image: url('~@/assets/images/channel/bank_kenya.png');
    }

    &.bankGhana {
      background-image: url('~@/assets/images/channel/bank_ghana.png');
    }

    &.bankTanzania {
      background-image: url('~@/assets/images/channel/bank_tanzania.png');
    }

    &.bankInterac {
      background-image: url('~@/assets/images/channel/bank_interac.png');
    }

    &.momopay {
      background-image: url('~@/assets/images/channel/momopay.png');
    }
  }

  &.rectangle {
    width: 190px;
    height: 45px;

    &.fasapay {
      background-image: url('~@/assets/images/channel/fasapay.png');
    }

    &.visa {
      background-image: url('~@/assets/images/channel/visa.png');
    }

    &.mobile {
      background-image: url('~@/assets/images/channel/mobile.png');
    }

    &.sticPay {
      background-image: url('~@/assets/images/channel/sticPay.png');
    }

    &.astropay {
      background-image: url('~@/assets/images/channel/astropay.png');
    }

    &.skrill {
      background-image: url('~@/assets/images/channel/skrill.png');
    }

    &.neteller {
      background-image: url('~@/assets/images/channel/neteller.png');
    }

    &.gopay {
      background-image: url('~@/assets/images/channel/gopay.png');
    }
  }

  &.diamond {
    width: 139px;
    height: 94px;

    &.unionpay {
      background-image: url('~@/assets/images/channel/unionpay.png');
    }

    &.unionpay_china {
      background-image: url('~@/assets/images/channel/unionpay_china.png');
    }

    &.masterCard {
      background-image: url('~@/assets/images/channel/masterCard.png');
    }

    &.jcb {
      background-image: url('~@/assets/images/channel/JCB.png');
    }

    &.ebuy {
      background-image: url('~@/assets/images/channel/ebuy.png');
    }

    &.perfectMoney {
      background-image: url('~@/assets/images/channel/perfect_money.png');
    }

    &.iDeal {
      background-image: url('~@/assets/images/channel/iDeal.png');
    }
  }

  &.cc {
    width: 156px;
    height: 87px;

    &.cc3way {
      background-image: url('~@/assets/images/channel/creditcard3Way.png');
    }

    &.cc2way {
      background-image: url('~@/assets/images/channel/creditcard2Way.png');
    }

    &.ccJCMC {
      background-image: url('~@/assets/images/channel/creditcardJCMC.png');
    }
  }

  &.visa {
    width: 150px;
    height: 36px;

    &.visaway {
      background-image: url('~@/assets/images/channel/visa.png');
    }
  }
}
