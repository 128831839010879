.default-box {
  border-radius: 8px;
  background-color: $white;
  padding: 20px 100px;

  .main {
    border-radius: 8px;
    background-color: $white;
    padding: 40px 120px;
    max-width: 1200px;
    margin: 0 auto;

    .max {
      display: grid;
      grid-template-columns: 30px calc(100% - 30px - 40px);
      column-gap: 40px;
      max-width: 780px;
      margin: 0 auto;

      &.hideStep {
        display: block;
      }
    }
  }
}

@media (max-width: 1200px) {
  .default-box {
    padding: 20px 60px;

    .main {
      @include rtl-sass-value(padding, 40px 0 40px 20px, 40px 20px 40px 0);
    }
  }
}

@media (max-width: 768px) {
  .default-box {
    padding: 20px;
  }
}

@media (max-width: 767px) {
  .default-box .main {
    @include rtl-sass-value(padding, 0, 0);

    .max {
      column-gap: 10px;
      grid-template-columns: 30px calc(100% - 30px - 10px);
      background-color: $white;
    }
  }
}
