@import './logo.scss';

.depositFunds-box {
  display: grid;
  grid-template-columns: calc(100% - 200px) 155px;
  column-gap: 45px;
  max-width: 750px;

  .form-left {
    p {
      font-size: 15px;
      color: $blue;
      text-transform: uppercase;
      margin-bottom: 10px;
      margin-top: 10px;
    }

    .form_main {
      max-width: 339px;

      .form-box /deep/ .el-form-item__content {
        line-height: unset;

        .default_info {
          font-size: 12px;
          color: rgb(98, 109, 113);
          margin-top: 5px;
        }
      }

      .info {
        font-size: 12px;
        color: rgb(98, 109, 113);
      }

      .data p {
        font-size: 14px;
      }

      .btn-blue {
        width: 100%;
        margin-top: 30px;
      }
    }
  }

  .form-right {
    width: 155px;
    margin-top: 60px;

    &.creditcard {
      width: 100%;
    }
  }
}

/deep/ .el-steps {
  .el-step:nth-child(1) {
    flex-basis: 180px !important;
  }

  .el-step:nth-child(2) {
    flex-basis: calc(100% - 230px) !important;
  }
}

@media (max-width: 1024px) {
  .depositFunds-box {
    grid-template-columns: calc(100% - 145px) 125px;
    column-gap: 20px;

    .form-right {
      width: 100px;
      margin-top: 0;

      .logo {
        &.rectangle {
          width: 105px;
          height: 26px;
        }

        &.diamond {
          width: 110px;
          height: 75px;
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .depositFunds-box {
    grid-template-columns: repeat(auto-fill, 100%);
    grid-template-rows: auto;
    grid-template-areas:
      'form-right'
      'form-left';

    .form-left {
      grid-area: form-left;
    }

    .form-right {
      grid-area: form-right;
      margin-top: 10px;
      margin-bottom: 20px;
      width: 100%;

      .logo {
        margin: 0 auto;
        &.rectangle {
          width: 190px;
          height: 45px;
        }

        &.rectangle {
          width: 190px;
          height: 45px;
        }
      }
    }
  }

  /deep/ .el-input__inner,
  /deep/ .el-select .el-input__inner {
    border-style: solid;
    border-width: $border-width;
    border-color: $border-color;
    border-radius: 6px;
    background-color: $light-gray;
  }
}
