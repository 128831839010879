
              @import "@/assets/css/variables.scss";
            


















































.reminder-box {
  border: 1px solid $primary;
  border-radius: 6px;
  padding: 15px;
  margin-bottom: 40px;
  width: 95%;
  .ins_container {
    margin-bottom: 1em;
  }
  ul {
    li {
      list-style-type: decimal;
      @include rtl-sass-prop(margin-left, margin-right, 1em);
      margin-bottom: 5px;
      font-size: 14px;
      color: $dark-gray;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
