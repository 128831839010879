$primary: #5ad6cf;
$success: #30bf58;
$danger: #cd2a2a;
$warning: #f39924;
$info: #8a8a99;
$secondary: #0e1236;
$border-color-base: #dfdfe5;
$background-color-base: #f8f8fd;
$text-title: #1e2125;
$text-primary: #333340;
$text-secondary: #676773;
$text-placeholder: #8a8a99;
$text-link: #1776e5;
$white: #ffffff;
$black: #000000;

$light-white: #e3f0fd;
$light-gray: #f2f3f7;
$dark-gray: #636e72;
$pu-light-gray: #ebeef5;
$pu-dark-gray: #c0c4cc;
$black: #000000;
$blue: $primary;
$light-blue: #004cb247;
$sky-blue: #006bff;
$future-blue: #004abc;
$bottle-blue: #014abd;
$gray-blue: #0914574f;
$red: #f21011;
$light-red: #e91d2c;
$primary-alt: $white;
// $secondary: $light-blue;

$theme-colors: (
  'primary': $primary,
  'primary-alt': $primary-alt,
  'secondary': $secondary,
  'white': $white,
  'light-white': $light-white,
  'light-gray': $light-gray,
  'dark-gray': $dark-gray,
  'blue': $blue,
  'black': $black,
  'light-blue': $light-blue,
  'sky-blue': $sky-blue,
  'future-blue': $future-blue,
  'bottle-blue': $bottle-blue,
  'gray-blue': $gray-blue,
  'red': $red,
  'light-red': $light-red,
);
